<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }} invoice particular
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="resetForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Title" v-model="type.title" outlined dense>

                            </v-text-field>
                            <span class="text-danger" v-if="$v.type.title.$error">This information is required</span>

                        </v-col>
                        <div class="col-12">
                            Description
                            <ckeditor v-model="type.description"  :config="editorConfig"></ckeditor>
                            <span class="text-danger"
                                  v-if="$v.type.description.$error">This information is required</span>
                        </div>
                        <v-col cols="6">
                            <v-text-field label="Amount" v-model="type.amount" outlined dense>

                            </v-text-field>
<!--                            <span class="text-danger"-->
<!--                                  v-if="$v.type.amount.$error">Amount is required</span>-->

                        </v-col>
                        <v-col cols="6">
                            <v-select
                                    :items="financial_heads"
                                    v-model="type.transaction_head_id"
                                    outlined
                                    dense
                                    label="Finance head"
                                    item-text="title"
                                    item-value="id"
                            >

                            </v-select>
                            <span class="text-danger"
                                  v-if="$v.type.transaction_head_id.$error">This information is required</span>

                        </v-col>
                        <div class="col-3">
                            <v-switch
                                    v-model="type.is_active"
                                    label="Status"
                            >
                            </v-switch>
                        </div>
                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed @click="resetForm" class="text-gray btn btn-standard cancel-btn">
                                    Cancel
                                </v-btn>
                                <v-btn depressed :loading="isBusy" @click="createOrUpdate"
                                       class="text-white ml-2 btn btn-primary ">
                                    Save
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import {required} from "vuelidate/lib/validators";
import FinancialPaymentTypeService from "@/services/finance/payment-type/FinancialPaymentTypeService";
import FinanceMixin from "@/mixins/FinanceMixin";

const financialPaymentTypeService = new FinancialPaymentTypeService();

export default {
    name: 'types',
    mixins: [FinanceMixin],
    validations: {
        type: {
            title: {required},
            description: {required},
            transaction_head_id: {required},

        }
    },
    computed: {

    },
    data() {
        return {
            dialog: false,
            edit: false,
            sizeExceed: false,
            isBusy: false,
            search: {
                active: "active",
            },
            types:[
                {
                    title:'Expense',
                    value:'expense'
                },
                {
                    title:'Income',
                    value:'income'
                },
            ],
            type: {
                title: null,
                description: null,
                transaction_head_id: null,
                amount: null,
                is_active: true
            },
          editorConfig: {
            versionCheck: false,
            toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ]
          },
        };
    },
    mounted() {
        this.getTransactionHeadings();
    },
    methods: {
        showModal(id = null) {
            this.__reset();
            if (id) {
                this.edit = true
                this.__get(id)
            }else
                this.edit = false;
            this.dialog = true;
        },
        __reset() {
            this.type = {
                title: null,
                description: null,
                transaction_head_id: null,
                amount: null,
                is_active: true
            }
        },
        hideModal() {
            this.dialog = false;
        },

        __get(id) {
            financialPaymentTypeService.show(id).then(response => {
                this.type = response.data.paymentType;
            })
        },

        createOrUpdate() {
            this.$v.type.$touch();
            if (this.$v.type.$error) {
                setTimeout(() => {
                    this.$v.type.$reset();
                }, 3000);
            } else {
                let fd = this.type;
                if (this.edit) {
                    this.__update(fd);
                } else {
                    this.__create(fd);
                }
            }
        },
        __update(fd) {
            this.isBusy = true;
            financialPaymentTypeService
                .update(this.type.id,fd)
                .then(response => {
                    this.isBusy = false;
                    this.$snotify.success("Information updated");

                    this.resetForm();
                })
                .catch(error => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                });
        },

        __create(fd) {
            this.isBusy = true;
            financialPaymentTypeService
                .store( fd)
                .then(() => {
                    this.isBusy = false;
                    this.resetForm();
                    this.$snotify.success("Information added");

                })
                .catch(() => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                });
        },
        resetForm() {
            this.$v.type.$reset();
            this.edit = false;
            this.type = {
                title: null,
                is_active: true
            };
            this.hideModal();
            this.$emit("refresh_type");
        }
    }
};
</script>
