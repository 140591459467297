<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage invoice particulars</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Invoice
                  particulars
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="__add()" class="mt-4 btn btn-primary mr-1" >
                  <i class="fa fa-plus"></i>
                  Add invoice particular
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    outlined
                    v-on:keyup.enter="__get()"
                    v-model="search.title"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-on:keyup.enter="__get()"
                    v-model="search.active"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    @click="__get()"
                    class="btn btn-primary btn-search w-35"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <div class="">
                <table class="table mt-3">
                  <thead>
                    <tr class="px-3">
                      <th class="px-3 wrap-column">Title</th>
                      <th class="px-3 wrap-column">Amount</th>
                      <th class="px-3 wrap-column">Heading</th>
                      <th class="px-3 wrap-column">Status</th>
                      <th class="px-2 text-center">Action</th>
                    </tr>
                  </thead>

                  <draggable
                    v-show="payment_types.length > 0"
                    @change="sort"
                    v-model="payment_types"
                    class="text-left"
                    tag="tbody"
                    handle=".handle"
                  >
                    <tr v-for="(type, index) of payment_types" :key="index">
                      <td class="px-3 wrap-column" scope="row">
                        <i class="cursor-drag fas fa-sort handle pr-2"></i>
                        <a href="#" @click="__edit(type.id)" class="mr-2">
                          {{ type.title }}</a
                        >
                      </td>

                      <td class="px-3 wrap-column">
                        {{ type.amount }}
                      </td>

                      <td class="px-3 wrap-column" v-if="type.transaction_head_id">
                        {{ type.heading_title }}
                      </td>

                      <td class="px-3 wrap-column">
                        <span
                          class="badge badge-success text-lg`"
                          v-bind:class="{
                            'badge-success': type.is_active,
                            'badge-danger': !type.is_active,
                          }"
                          >{{ type.is_active ? "Active" : "Inactive" }}</span
                        >
                      </td>
                      <td class="px-1 text-center">
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <slot>
                              <span>
                                <i class="flaticon-more-1"></i> </span
                            ></slot>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a href="#" class="navi-link" @click="__edit(type.id)">
                                <span class="navi-icon">
                                  <i class="flaticon-edit"></i>
                                </span>
                                <span class="navi-text"> Edit </span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a href="#" class="navi-link" @click="__delete(type.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text"> Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </td>
                    </tr>
                  </draggable>
                  <tr v-if="payment_types.length == 0">
                    <td colspan="5" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                </table>
                <b-pagination
                  v-if="payment_types.length > 0"
                  class="pull-right mt-7"
                  @input="__get"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                ></b-pagination>
              </div>
            </div>
          </div>
          <create-and-update ref="type" @refresh_type="__get"></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from "vuedraggable";
import FinancialPaymentTypeService from "@/services/finance/payment-type/FinancialPaymentTypeService";

const financialPaymentTypeService = new FinancialPaymentTypeService();

export default {
  name: "payment_types",
  components: {
    CreateAndUpdate,
    draggable,
  },
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      payment_types: [],
      page: null,
      perPage: null,
      total: null,
      search: {
        active: "active",
        is_parent: "null",
      },
      dragging: false,
      loading: false,
    };
  },
  computed: {
    topic_id() {
      return this.$route.params.topic_id;
    },
    syllabus_id() {
      return this.$route.params.syllabus_id;
    },
  },
  mounted() {
    this.__get();
  },
  methods: {
    __get() {
      this.loading = true;
      financialPaymentTypeService
        .paginate(this.search)
        .then((response) => {
          this.payment_types = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
    __add() {
      this.$refs["type"].showModal();
    },
    __edit(id) {
      this.$refs["type"].showModal(id);
    },
    manageCategory() {
      this.$refs["manageCategory"].showModal();
    },

    __delete(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            financialPaymentTypeService
              .delete(id)
              .then((response) => {
                this.__get();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    sort() {
      financialPaymentTypeService
        .sort(this.payment_types)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.__get();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    detail(id) {
      this.$router.push({ name: "type-photo", params: { id: id } });
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
